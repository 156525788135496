import React, { useState } from "react";
import axios from "axios";
import { Button, TextField, Snackbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DropFileInput from './components/drop-file-input/DropFileInput'; // Assurez-vous que ce chemin est correct
import "./cvupload.css"; // Assurez-vous que ce chemin est correct
import { axiosInstance2 } from "../../axios";
import { Rings } from "react-loader-spinner";

const CvUpload = () => {
  const [file, setFile] = useState(null);
  const [userInput, setUserInput] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)

  const onFileChange = (files) => {
    if (files.length > 0) {
      setFile(files[0]);
    }
  };

  const handleUserInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleUpload = async () => {
    setLoading(true)
    
    try {
      const formData = new FormData();
      formData.append("cv", file);
      formData.append("user_input", userInput);

      const response = await axiosInstance2.post("upload_cv/upload/",
        formData, { headers: { "Content-Type": "multipart/form-data" } });

      // const response = await axios.post(
      //   "https://interview-backend-app.onrender.com/upload_cv/upload/",
      //   formData,
      //   { headers: { "Content-Type": "multipart/form-data" } }
      // );

      const extractedText = response.data.text;
      const userInterests = response.data.userInput;
      const result = `Text extracted from CV: ${extractedText}\nUser Interests: ${userInterests}`;
      setSnackbarMessage(result);
      setSnackbarOpen(true);

      navigate("/interview", { state: { result } });
    } catch (error) {
      console.error("Error uploading CV:", error);
      setSnackbarMessage("Error uploading CV. Please try again.");
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  

  return (
    <>
            {loading ? (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "10%" }}>
          <Rings
            visible={true}
            height="80"
            width="80"
            color="#FFF"
            ariaLabel="rings-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>) : (
        <>
          <div className="full">
            <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
            <div className="bodyy">
              <div className="imgtxt">
              <img src="assets/cvupload.png" alt="img" className="image" />
              <div className="textuploadcv">
                Upload your CV to tailor interview questions based on your experience, and fill out the interest field to help us better understand your passions and focus on your areas of interest.
              </div>
              </div>
              <div className="box">
                <h2 className="header">Upload Your CV Here</h2>
                <DropFileInput onFileChange={onFileChange} />
                <TextField
                  label="Your Interests"
                  variant="outlined"
                  value={userInput}
                  onChange={handleUserInputChange}
                  style={{ marginBottom: 16, width: "100%" }}
                />
                <Button
                  variant="contained"
                  onClick={handleUpload}
                  style={{
                    backgroundColor: "#4267b2", color: "#ffffff", width: "100%"
                  }}
                >
                  Upload CV
                </Button>
                {/* Snackbar for error or success messages */}
                <Snackbar
                  open={snackbarOpen}
                  autoHideDuration={6000}
                  onClose={handleCloseSnackbar}
                  message={snackbarMessage}
                />
              </div>
            </div>
          </div>
        </>
      )}

    </>
  );
};

export default CvUpload;