import React from 'react'
import { NavLink } from 'react-router-dom'
import logo from "../logo-eratech.png"
import useLogout from "../hooks/useLogout"
import { useNavigate } from "react-router-dom"
import useAuth from '../hooks/useAuth'
import "./navbarStyle.css";

export default function Navbar() {
    const logout = useLogout()
    const navigate = useNavigate()
    const { accessToken } = useAuth();

    async function onLogout() {
        try {
            await logout(); // Call your logout API endpoint
            navigate('/'); // Redirect to home page or login page
        } catch (error) {
            // Handle logout errors
        }
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-transparent">
            <div className="container">
                {/* LOGO */}
                <a className="navbar-brand fs-4" href="/"><img src={logo} width={"130px"} alt='Logo' /></a>
                {/* Toggle Btn */}
                <button className="navbar-toggler shadow-none border-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                {/* Side bar */}
                <div className="sidebar offcanvas offcanvas-start" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title" id="offcanvasNavbarLabel"><img src={logo} width={"130px"} alt='Logo' /></h5>
                        <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body">
                        <ul className='navbar-nav align-items-right fs-5 flex-grow-1 pe-3' style={{justifyContent: "right"}}>
                            {accessToken ? (
                                <>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link" href="#drop" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <img
                                                src="/assets/user.jpg"
                                                alt="Participant"
                                                className="user-photo"
                                            />
                                        </a>
                                        <ul className="dropdown-menu">
                                            <li><a href='#Login' className='dropdown-item text-decoration-none' onClick={onLogout}>Logout</a></li>
                                        </ul>
                                    </li>
                                </>
                            ) : (
                                <>
                                    <li className='nav-item mx-2'><NavLink className={'nav-link'} to={'/auth/login'}>Login</NavLink></li>
                                    <li className='nav-item mx-2'><NavLink className={'nav-link'} to={'/auth/register'}>SignUp</NavLink>
                                    </li>
                                </>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    )
}