import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import "./interview.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation, useNavigate } from "react-router-dom";
import useLogout from "../../hooks/useLogout";
import { Rings } from "react-loader-spinner";
import useAuth from "../../hooks/useAuth"
import { axiosInstance2, axiosPrivateInstance } from "../../axios";

const OPENAI_API_KEY = "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";

function InterviewPage() {
  const [loading, setLoading] = useState(false)
  const logout = useLogout();
  const navigate = useNavigate();
  const { state } = useLocation();
  const result = state.result;

  const [participants, setParticipants] = useState([
    { id: 1, name: "khalid", photo: "/assets/user.jpg" },
    { id: 2, name: "Jarvis", photo: "/assets/ai.jpg" },
  ]);

  const [conversation, setConversation] = useState([
    {
      role: "assistant",
      content:
        "Hello, I am Jarvis, your virtual recruiter. I will ask questions and await your responses. My questions will not exceed 2 lines. Get ready, let's begin the interview.",
    },
  ]);

  const [audioUrl, setAudioUrl] = useState("");
  const [isListening, setIsListening] = useState(false);
  const [isTalking, setIsTalking] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [interviewEnded, setInterviewEnded] = useState(false);

  const endInterview = () => {
    setInterviewEnded(true);
    setLoading(true)
  };



  const recognition = new (window.SpeechRecognition ||
    window.webkitSpeechRecognition)();
  recognition.interimResults = true;
  recognition.lang = navigator.language || "en-US";

  const addMessageToConversation = (role, content) => {
    setConversation((prevConversation) => [
      ...prevConversation,
      { role, content },
    ]);
  };

  const handleListenStart = () => {
    setIsListening(true);
    console.log("Écoute en cours...");
  };

  const handleListenEnd = () => {
    setIsListening(false);
    console.log("Fin de l'écoute.");
  };

  const handleListenError = (error) => {
    console.error("Erreur pendant l'écoute :", error);
  };

  recognition.onstart = handleListenStart;
  recognition.onend = handleListenEnd;
  recognition.onerror = handleListenError;
  recognition.onresult = (event) => {
    for (const res of event.results) {
      const text = res[0].transcript;
      if (res.isFinal) {
        addMessageToConversation("user", text);
        sendToAI(text); // Envoyer le texte à l'API
      }
    }
  };

  const startListening = () => {
    recognition.start();
  };
  const sendFinalPrompt = async () => {
    try {
      const finalPrompt = `
      Please provide detailed feedback on the conversation we had, focusing especially on my responses. Consider the following aspects:
      1. Clarity: Evaluate whether the information provided was clear and easy to understand.
      2. Effectiveness: Assess whether the responses effectively addressed your questions or concerns.
      3. Engagement: Reflect on how engaging the conversation was and whether you felt actively involved.
      4. Improvement: Identify specific areas where we can enhance the interaction.
      (keep 1. 2. 3. 4.)
      Your feedback is crucial for our continuous improvement. Please ensure your response is detailed, providing valuable insights. Aim for a minimum of 20 lines to convey a thorough evaluation.
      
      Thank you for taking the time to share your thoughts!
      `;

      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-3.5-turbo",
          messages: conversation.concat({ role: "user", content: finalPrompt }),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${OPENAI_API_KEY}`,
          },
        }
      );

      const feedback = response.data.choices[0].message.content;
      setFeedback(feedback);
    } catch (error) {
      console.error("Error sending final prompt:", error);
    }
  };

  const fetchAudio = async (text) => {
    try {
      const response = await axiosInstance2.post("text-to-speech/",
        { text }, { responseType: "blob" }
      )

      const audioUrl = URL.createObjectURL(await response.data);
      setAudioUrl(audioUrl);
      const audioElement = new Audio(audioUrl);
      audioElement.addEventListener("ended", () => {
        setIsTalking(false);
      });
      audioElement.play();
    } catch (error) {
      console.error("Erreur lors de la récupération de l'audio", error);
    }
  };

  const sendToAI = async (text) => {
    try {
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-3.5-turbo",
          messages: conversation.concat({ role: "user", content: text }),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${OPENAI_API_KEY}`,
          },
        }
      );

      const assistantResponse = response.data.choices[0].message.content;
      addMessageToConversation("assistant", assistantResponse);
      await fetchAudio(assistantResponse);

      // Start the sound wave animation when the AI is responding
      setIsTalking(true);
    } catch (error) {
      console.error("Erreur lors de l'envoi de la requête :", error);
    }
  };
  async function Logout() {
    try {
      await logout(); // Call your logout API endpoint
    } catch (error) {
      // Handle logout errors
    }
  }

  useEffect(() => {
    if (interviewEnded) {
      sendFinalPrompt(); // Call a function to send the final prompt
    } else {
      addMessageToConversation(
        "user",
        `${result}, we can start the interview!`
      );
      // Send the initial prompt to AI
    }
  }, [interviewEnded]);

  const chatBarRef = useRef(null);

  useEffect(() => {
    chatBarRef.current.scrollTop = chatBarRef.current.scrollHeight;
  }, [conversation]); // Trigger the effect whenever the conversation changes

  useEffect(() => {
    if (feedback != "") {
      navigate("/feedback", { state: { feedback } });
      Logout();
    }
  }, [feedback]);

  useEffect(() => {
    async function getUser() {
        const { data } = await axiosPrivateInstance.get('auth/user')
        setParticipants([
          { id: 1, name: data.username, photo: "/assets/user.jpg" },
          { id: 2, name: "Jarvis", photo: "/assets/ai.jpg" },
        ])
    }

    getUser();
}, [])

  return (
    <>
      {loading ? (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "10%" }}>
          <h2 className="text-white">Genereting the feedback...</h2>
          <Rings
            visible={true}
            height="80"
            width="80"
            color="#FFF"
            ariaLabel="rings-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        <div className="App">
          <main className="App-main">
            <div className="container-fluid">
              <div className="meeting-container">
                <div className="chatbot-section">
                  <div className="participants-container">
                    <h3 className="section-title">Participants</h3>
                    <div className="participants-list">
                      {participants.map((participant, index) => (
                        <div key={index} className="participant-card">
                          <img
                            src={participant.photo}
                            alt={`${participant.name}'s Photo`}
                            className="participant-photo"
                          />
                          <div className="participant-details">
                            <p className="participant-name">{participant.name}</p>
                            <p className="participant-role">{participant.role}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="meeting-container">
                      <h3 className="section-title">Chatbot</h3>
                      <div className="chat-content">
                        <div className="chat-bar" ref={chatBarRef}>
                          {conversation.map((message, index) => (
                            <div
                              key={index}
                              className={`chat-message ${index % 2 === 0 ? 'bot-message' : 'user-message'
                                }`}
                            >
                              {message.content}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className="left-section">
                <div className="interviewer-box">
                  <h3>Interviewer Bot</h3>
                </div>
                <p className="Begin-recording">
                  Begin recording your answers below:
                </p>

                <div>
                  {isTalking ? (
                    <div className="sound-wave">
                      <div className="rectangle"></div>
                      <div className="rectangle"></div>
                      <div className="rectangle"></div>
                      <div className="rectangle"></div>
                      <div className="rectangle"></div>
                      <div className="rectangle"></div>
                      <div className="rectangle"></div>
                      <div className="rectangle"></div>
                      <div className="rectangle"></div>
                      <div className="rectangle"></div>
                      <div className="rectangle"></div>
                    </div>
                  ) : (
                    <img src="/assets/ai.jpg" className="interviewer-photo" />
                  )}
                </div>
                <div className="button-section">
                  <button
                    className={`App-recordButton ${isListening ? 'recording' : ''}`}
                    disabled={isListening || isTalking}
                    onClick={startListening}
                  >
                    {isListening ? "Stop Recording" : "Start Recording"}
                  </button>
                  <button className="App-endInterviewButton" onClick={endInterview}
                  disabled={isListening || isTalking}>
                    End Interview
                  </button>
                </div>


              </div>
            </div>
          </main> </div>)}
    </>
  );
}

export default InterviewPage;
