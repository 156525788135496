import axios from "axios"

const API_URL = "https://interview-backend-app.onrender.com/api/"
const API_URL2 = "https://interview-backend-app.onrender.com/"

export const axiosInstance = axios.create({
    baseURL: API_URL,
    withCredentials: true,
    headers: {
        "Content-Type": "application/json"
    }
})

export const axiosInstance2 = axios.create({
    baseURL: API_URL2,
    withCredentials: true,
    headers: {
        "Content-Type": "application/json"
    }
})

export const axiosPrivateInstance = axios.create({
    baseURL: API_URL,
    withCredentials: true,
    headers: {
        "Content-Type": "application/json"
    }
})