import { useState } from "react";
import "./Auth.css";
import { useNavigate, useLocation } from 'react-router-dom'
import useAuth from "../../hooks/useAuth";
import { axiosInstance } from "../../axios";
import { Rings } from "react-loader-spinner";
import { ToastContainer, toast } from 'react-toastify';


const Login = () => {
  const emailIcon = "/assets/email.png";
  const passwordIcon = "/assets/password.png";

  const { setAccessToken, setCSRFToken } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const fromLocation = location?.state?.from?.pathname || '/'
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  function onEmailChange(event) {
    setEmail(event.target.value)
  }

  function onPasswordChange(event) {
    setPassword(event.target.value)
  }

  async function onSubmitForm(event) {
    event.preventDefault()

    setLoading(true)

    try {
      const response = await axiosInstance.post('auth/login', JSON.stringify({
        email,
        password
      }))
      setAccessToken(response?.data?.access_token)
      setCSRFToken(response.headers["x-csrftoken"])
      setEmail()
      setPassword()
      setLoading(false)

      if (response?.data) toast.success("Logged in successfully");

      navigate(fromLocation, { replace: true })
    } catch (error) {
      setLoading(false)
      // TODO: handle errors
      if (error?.response?.data) {
        toast.error(error.response.data.detail);
      } else {
        toast.error("Login failed. Please try again!");
      }
    }
  }

  return (
    <>
      <ToastContainer />
      {loading ? (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "10%" }}>
          <Rings
            visible={true}
            height="80"
            width="80"
            color="#FFF"
            ariaLabel="rings-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        <div className="main-container">
          <div className="auth-container">
            <div className="header">
              <div className="text">Login</div>
              <div className="underline"></div>
            </div>
            <div className="form">
              <form onSubmit={onSubmitForm}>
                <div className="inputs">
                  <div className="input">
                    <img src={emailIcon} alt="Email" />
                    <input
                      type="email"
                      placeholder="Email"
                      id="email"
                      name="email"
                      value={email}
                      onChange={onEmailChange}
                      required
                    />
                  </div>
                  <div className="input">
                    <img src={passwordIcon} alt="Password" />
                    <input
                      type="password"
                      placeholder="Password"
                      id="password"
                      name="password"
                      value={password}
                      onChange={onPasswordChange}
                      required
                    />
                  </div>
                  <div className="forgot-password">
                    Lost Password? <span>Click Here!</span>
                  </div>
                  <div className="submit-container">
                    <input className="submit" type="submit" value="Login" />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
