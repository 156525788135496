import React from "react";
import { Routes, Navigate, Route } from "react-router-dom";
import InterviewPage from "./Pages/InterviewPage/InterviewPage.jsx";
import Login from "./Pages/Auth/Login.js";
import Register from "./Pages/Auth/Register.js";
import CvUpload from "./Pages/CvUploadPage/cvUpload.jsx";
// import Home from "./Pages/Home.js";
import PersistLogin from "./components/PersistLogin.js";
import User from "./Pages/Auth/User.js";
import AuthMiddleware from "./middleware/Auth.js";
import Navbar from "./components/Navbar.js";
import "bootstrap/dist/js/bootstrap.bundle.min";
import FeedbackPage from "./Pages/FeedbackPage/FeedbackPage.jsx";
import 'react-toastify/dist/ReactToastify.css';
import AuthMiddleware2 from "./middleware/Auth2.js";

function App() {
  return (

    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<PersistLogin />}>
          <Route element={<AuthMiddleware />}>
            <Route index element={<CvUpload />} />
          </Route>
          <Route path="cvUpload" element={<AuthMiddleware />}>
            <Route index element={<CvUpload />} />
          </Route>
          <Route path="interview" element={<AuthMiddleware />}>
            <Route index element={<InterviewPage />} />
          </Route>
          {/* <Route path="feedback" element={<AuthMiddleware />}>
             <Route index element={<FeedbackPage />} />
           </Route> */}
          <Route path="feedback" element={<FeedbackPage />} />
          <Route path="/auth">
            <Route path="login" element={<AuthMiddleware2 />} >
              <Route index element={<Login />} />
            </Route>
            <Route path="register" element={<AuthMiddleware2 />} >
              <Route index element={<Register />} />
            </Route>
            <Route path="user" element={<AuthMiddleware />}>
              <Route index element={<User />} />
            </Route>
          </Route>
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
}

export default App;
