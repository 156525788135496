import React from 'react'
import { useLocation } from "react-router-dom";
// import axios from "axios";
import "./FeedbackPage.css";
import { axiosInstance2 } from '../../axios';
// import logo from "../../logo-eratech.png"

const FeedbackPage = () => {
  const { state } = useLocation();
  const feedback = state.feedback
  const generatePDF = async () => {
    try {
      const response = await axiosInstance2.post('rapport_pdf/generate_pdf_api/', {
        feedback
      }, {
        responseType: "blob",
      })
      const pdfUrl = window.URL.createObjectURL(new Blob([response.data]));

      const downloadLink = document.createElement('a');
      downloadLink.href = pdfUrl;
      downloadLink.download = 'rapport.pdf';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      window.URL.revokeObjectURL(pdfUrl);
    } catch (error) {
      console.error('Erreur lors de la génération du PDF:', error);
    }
  };
  return (
    <div>
      <div className='feedback-container'>
        <div className="box">
          <h3 className='title'>Interview Feedback Download</h3>
          <p>Thank you for participating in the recent interview! We appreciate your time and interest in EraTech. Below, you can download the feedback from your interview.</p>
          <button className='button' onClick={generatePDF}>Dowloand the feedback PDF</button>
          <h3 className='title'>Additional Resources:</h3>
          <p>Thank you again for your interest in EraTech. If you have any questions or require further clarification, please don't hesitate to reach out to us by mail.
          </p>
          <span className='email'>eratech@gmail.com</span>

        </div></div></div>
  )
}

export default FeedbackPage