import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Auth.css";
import { axiosInstance } from "../../axios";
import { Rings } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';


const Register = () => {
  const personIcon = "/assets/person.png";
  const emailIcon = "/assets/email.png";
  const passwordIcon = "/assets/password.png";

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [username, setUsername] = useState()
  const [passwordConfirmation, setPasswordConfirmation] = useState()

  function onEmailChange(event) {
    setEmail(event.target.value)
  }

  function onPasswordChange(event) {
    setPassword(event.target.value)
  }

  function onUsernameChange(event) {
    setUsername(event.target.value)
  }

  function onPasswordConfirmationChange(event) {
    setPasswordConfirmation(event.target.value)
  }

  async function onSubmitForm(event) {
    event.preventDefault();

    setLoading(true)

    try {
      const response = await axiosInstance.post('auth/register', JSON.stringify({
        username,
        email,
        password,
        password2: passwordConfirmation
      }))

      toast.success(response.data);

      setEmail('')
      setPassword('')
      setUsername('')
      setPasswordConfirmation('')
      setLoading(false)

      navigate('/auth/login')
    } catch (error) {
      setLoading(false)
      // TODO: handle errors
      if (error?.response?.data) {
        if (error.response.data.email) toast.error(error.response.data.email[0]);
        if (error.response.data.password) toast.error(error.response.data.password);
      } else {
        toast.error("Registration failed. Please try again.");
      }
    }
  }

  return (
    <>
      <ToastContainer />
      {loading ? (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "10%" }}>
          <Rings
            visible={true}
            height="80"
            width="80"
            color="#FFF"
            ariaLabel="rings-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        <div className="auth-container">
          <div className="header">
            <div className="text">Sign Up</div>
            <div className="underline"></div>
          </div>
          <div className="form">
            <form onSubmit={onSubmitForm}>
              <div className="inputs">
                <div className="input">
                  <img src={personIcon} alt="Name" />
                  <input
                    type="text"
                    placeholder="Name"
                    id="name"
                    name="name"
                    onChange={onUsernameChange}
                    required
                  />
                </div>
                <div className="input">
                  <img src={emailIcon} alt="Email" />
                  <input
                    type="email"
                    placeholder="Email"
                    id="email"
                    name="email"
                    onChange={onEmailChange}
                    required
                  />
                </div>
                <div className="input">
                  <img src={passwordIcon} alt="Password" />
                  <input
                    type="password"
                    placeholder="Password"
                    id="password"
                    name="password"
                    onChange={onPasswordChange}
                    required
                  />
                </div>
                <div className="input">
                  <img src={passwordIcon} alt="Password Comfirm" />
                  <input
                    type="password"
                    placeholder="Confirm Password"
                    id="passwordConfirmation"
                    name="passwordConfirmation"
                    onChange={onPasswordConfirmationChange}
                    required
                  />
                </div>
                {/* <div className="forgot-password">
                  Alredy have an account? <span>Login</span>
                </div> */}
                <div className="submit-container">
                  <input className="submit" type="submit" value="Sign Up" />
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default Register;
